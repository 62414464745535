<template>
    <div class="toggle-block">
        <div @click="isOpen = !isOpen" class="toggle-block__header">
            <h3 class="toggle-block__title">{{ title }}</h3>
            <img
                src="@/assets/images/icons/arrow-down.svg"
                class="toggle-block__img"
                :class="{'toggle-block__img_open': isOpen}"
                alt="arrow">
        </div>

        <div v-if="isOpen" class="toggle-block__content">
            <div class="toggle-block__employee">
                <img src="@/assets/images/icons/emp.svg" class="toggle-block__employee-icon" alt="employee">

                <div class="toggle-block__employee-wrapper">
                    <div class="toggle-block__employee-name">
                        {{ employee?.employee_full_name }} {{
                            employee?.employee_job_title ? `(${employee?.employee_job_title})` : ''
                        }}
                    </div>

                    <p class="toggle-block__employee-text">Ответственный</p>
                </div>
            </div>
            <slot name="body"/>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

defineProps({
    title: {
        type: String,
        required: true
    },
    employee: {
        type: Object
    }
})

const isOpen = ref(false)
</script>

<style scoped lang="scss">
.toggle-block {
    width: 100%;
    padding: 0 24px 24px 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);

    &__header {
        width: 100%;
        padding-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        cursor: pointer;
    }

    &__img {
        width: 24px;
        transition: .3s;

        &_open {
            transform: rotate(-180deg);
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__content {
        margin-top: 20px;
        border-top: 1px solid $light-gray-1;
    }

    &__employee {
        width: 384px;
        height: 56px;
        padding: 0 12px;
        margin-top: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        background-color: $light;
    }

    &__employee-icon {
        width: 24px;
    }

    &__employee-wrapper {
        margin-left: 12px;
    }

    &__employee-name {
        font-size: 14px;
        font-weight: 400;
    }

    &__employee-text {
        margin-top: 4px;
        color: $gray;
        font-size: 12px;
        font-weight: 400;
    }
}
</style>
