<template>
    <div class="vacancy">
        <router-link to="/vacancies" class="vacancy__breadcrumbs">
            <img src="@/assets/images/icons/back-arrow.svg" alt="back">
            <p>Вакансии</p>
        </router-link>
        <h1 class="vacancy__title main-title">{{ vacancy.title }}</h1>
        <div class="vacancy__row" v-if="vacancy.status && fieldsVacancies.vacancy">
            <div class="vacancy__wrapper container">
                <MainBlock :vacancy="vacancy" class="vacancy__main-block" />

                <ToggleBlock v-if="fieldsVacancies?.vacancy?.responsible?.extended_form?.employee_id"
                    title="Проверка документов" :employee="fieldsVacancies?.vacancy?.responsible?.extended_form"
                    class="vacancy__questionnaire-block">
                    <template #body>
                        <VerificationDocuments :extendedFormId="fieldsVacancies?.vacancy?.extended_form_id"
                            :documents="fieldsVacancies?.vacancy?.documents?.fields" />
                    </template>
                </ToggleBlock>
                <ToggleBlock v-if="fieldsVacancies?.vacancy?.responsible?.medical?.employee_id"
                    title="Медицинская проверка" :employee="fieldsVacancies?.vacancy?.responsible?.medical"
                    class="vacancy__questionnaire-block" />
                <ToggleBlock v-if="fieldsVacancies?.vacancy?.responsible?.buying_tickets?.employee_id"
                    title="Покупка билетов" :employee="fieldsVacancies?.vacancy?.responsible?.buying_tickets"
                    class="vacancy__questionnaire-block">
                    <template #body>
                        <BuyingTickets
                            v-if="fieldsVacancies?.vacancy?.responsible?.buying_tickets?.buying_tickets_files || fieldsVacancies?.vacancy?.responsible?.buying_tickets?.buying_tickets_description"
                            :documents="fieldsVacancies?.vacancy?.responsible?.buying_tickets?.buying_tickets_files"
                            :availableRouteMemo="fieldsVacancies?.vacancy?.responsible?.buying_tickets?.available_route_memo"
                            :text="fieldsVacancies?.vacancy?.responsible?.buying_tickets?.buying_tickets_description" />
                    </template>
                </ToggleBlock>
                <VButton label="Опубликовать вакансию" class="vacancy__publish" clickEffect v-if="!fieldsVacancies.vacancy.is_published &&
            route.meta.type === 'vacancy'
            " @click="publish" />

                <VButton label="Создать вакансию" class="vacancy__publish" clickEffect v-if="!fieldsVacancies.vacancy.is_published &&
            route.meta.type === 'template'
            " link :to="`/templates/${vacancyId}/create`" />
            </div>

            <ActionsMenu class="vacancy__actions-menu" :specialityName="vacancy.title"
                :isPublished="fieldsVacancies.vacancy.is_published" />
        </div>

        <ModalWindow v-if="showPopupErrorModal" @close="showPopupErrorModal = false" class="vacancy__modal">
            <VacancyErrorModal @close="showPopupErrorModal = false" title="Вакансия <br> не опубликована"
                description="Проверьте правильность заполненных данных" />
        </ModalWindow>

        <AccessClosed v-if="vacancy.blocked_by_contract" />
    </div>
</template>

<script setup>
import { onActivated, onDeactivated, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

import VacancyErrorModal from "@/components/ModalWindow/ModalBodyes/VacancyErrorModal";
import vacancies from "@/api/vacancies";
import ActionsMenu from "@/components/VacancyDetailCard/ActionsMenu";
import MainBlock from "@/components/VacancyDetailCard/MainBlock";
import ToggleBlock from "@/components/VacancyDetailCard/ToggleBlock";
import VerificationDocuments from "@/components/VacancyDetailCard/VerificationDocuments";
import BuyingTickets from "@/components/VacancyDetailCard/BuyingTickets";
import AccessClosed from "@/components/AccessClosed"

const route = useRoute();
const router = useRouter();

const vacancyId = ref(route.params.id);

const vacancy = ref({});
const fieldsVacancies = ref({});

const getVacancy = async () => {
    try {
        vacancy.value = await vacancies.getVacancy(vacancyId.value);
    } catch (error) {
        console.log(error);
    }
};

const getFieldsVacancies = async () => {
    try {
        fieldsVacancies.value = await vacancies.getFieldsVacancies({
            vacancy_id: vacancyId.value,
        });
    } catch (error) {
        console.log(error);
    }
};

const showPopupErrorModal = ref(false);
const publish = async () => {
    try {
        await vacancies.createVacancies(vacancyId.value);
        await router.push("/vacancies");
    } catch (error) {
        showPopupErrorModal.value = true;
        console.log(error);
    }
};

onActivated(async () => {
    window.scrollTo(0, -10000)
    vacancyId.value = await route.params.id
    await getVacancy();
    await getFieldsVacancies();
});

onDeactivated(() => {
    vacancy.value = {}
    fieldsVacancies.value = {}
})
</script>

<style scoped lang="scss">
.vacancy {
    padding-bottom: 30px;

    &__breadcrumbs {
        margin-top: 12px;
        display: flex;
        align-items: center;
        color: #8F9CAC;
        text-decoration: none;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;

        p {
            margin-left: 4px;
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__title {
        margin-top: 8px;
    }

    &__row {
        margin-top: 24px;
        display: flex;
        align-items: flex-start;
    }

    &__actions-menu {
        margin-left: 32px;
    }

    &__questionnaire-block {
        margin-top: 24px;
    }

    &__extended-questionnaire-block {
        margin-top: 24px;
    }

    &__publish {
        width: 437px;
        margin-top: 24px;
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }

    &__header {
        &::v-deep(.v-breadcrumbs) {
            .v-breadcrumbs__item:nth-child(2) .v-breadcrumbs__arrow {
                display: none !important;
            }
        }
    }
}
</style>
