<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="modal-body__title">Удалить {{ title }}</h3>
        <p class="modal-body__description">Вы действительно хотите удалить {{ title }}?</p>

        <div class="modal-body__btns">
            <VButton
                label="Удалить"
                bg="#DD4646"
                bgHover="#D10E0E"
                color="#fff"
                class="modal-body__btn"
                :disabled="disabled"
                @click="deleteDraft"/>

            <VButton
                label="Отменить"
                bg="#E4E7EE"
                color="#1E3959"
                bgHover="#BBC4CD"
                colorHover="#1E3959"
                class="modal-body__btn"
                @click="$emit('close')"/>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

defineProps({
    title: {
        type: String,
        required: true
    }
})

const emit = defineEmits(["delete"]);

const disabled = ref(false)
const deleteDraft = () => {
    disabled.value = true
    emit('delete')
}
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__title {
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        max-width: 256px;
        margin-top: 8px;
        font-size: 16px;
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;

        &::v-deep(.v-button.v-button_disabled) {
            background-color: #FFC5C5 !important;
        }
    }

    &__btn {
        width: 100%;

        &:first-child {
            &:active {
                background-color: #A61F1F !important;
            }
        }

        &:last-child {
            margin-top: 12px;

            &:active {
                color: #1E3959 !important;
                background-color: #8F9CAC !important;
            }
        }
    }
}
</style>
